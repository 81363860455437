import { useSwitchChain } from "wagmi";
import { userBalanceProp } from "../../types/types";
import { ChainsLogo } from "../../web3/chains";

interface Prop {
  token: userBalanceProp;
}

const SwitchNetworkModalBody = ({ token }: Prop) => {
  const { switchChain } = useSwitchChain();
  return (
    <div className="flex flex-col">
      <div className="flex justify-center mb-5">
        <img
          src={ChainsLogo[token.chainId]}
          width={60}
          className="rounded-full"
        />
      </div>
      <p className="mb-5">
        You need to switch your network to {token.chainName}
      </p>
      <button
        className="radial-gradient-button cursor-pointer"
        onClick={() => switchChain({ chainId: token.chainId })}
      >
        Switch Network
      </button>
    </div>
  );
};

export default SwitchNetworkModalBody;
