"use client";
import { useState } from "react";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import { ChainsLogo, supportedChains } from "../../../web3/chains";
import Modal from "../../../components/common/Modal";
import NetworksModal from "../../../components/NetworksModal/NetworksModal";

export const NetworkToDisplay = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { connectedChainId } = useWalletAccount();
  const handelOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div>
      {connectedChainId && (
        <div className="cursor-pointer" onClick={() => handelOpenModal()}>
          <img
            className="rounded-full"
            src={ChainsLogo[connectedChainId]}
            width={30}
          />
        </div>
      )}

      <Modal
        titleLeft="Select NetWork"
        isOpen={isModalOpen}
        closeModalHandler={handelOpenModal}
        size="large"
      >
        <NetworksModal
          networks={supportedChains}
          closeModalHandler={handelOpenModal}
          isDestination={false}
        />
      </Modal>
    </div>
  );
};
