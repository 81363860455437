//Real token information

import { SupportedChainId } from "@/web3/chains";
import { BridgeAddressProps } from "../types/types";

export const pairChains: Record<SupportedChainId, SupportedChainId[]> = {
  // [SupportedChainId.fantom]: [SupportedChainId.polygon, SupportedChainId.sonic],
  [SupportedChainId.fantom]: [SupportedChainId.sonic],
  // [SupportedChainId.polygon]: [SupportedChainId.fantom],
  [SupportedChainId.sonic]: [SupportedChainId.base, SupportedChainId.fantom],
  [SupportedChainId.base]: [SupportedChainId.sonic],
};

export const realTokenList = [
  {
    chainId: 8453,
    tokenAddress: "0xe094C430903C3Fe539D639123e94cE06D1feCB4d",
    chainName: "base",
    tokenName: "Symemeio",
    decimals: 18,
    dstChains: [SupportedChainId.sonic],
  },
  {
    chainId: 146,
    tokenAddress: "0x99E7bD153585368c975FcFcdD2cCF0B5172963Ad",
    chainName: "sonic",
    tokenName: "Symemeio",
    decimals: 18,
    dstChains: [SupportedChainId.base],
  },
  {
    chainId: 146,
    tokenAddress: "0xde55b113a27cc0c5893caa6ee1c020b6b46650c0",
    chainName: "sonic",
    tokenName: "DEUS",
    decimals: 18,
    dstChains: [SupportedChainId.fantom],
  },
  {
    chainId: 250,
    tokenAddress: "0xDE55B113A27Cc0c5893CAa6Ee1C020b6B46650C0",
    chainName: "fantom",
    tokenName: "DEUS",
    decimals: 18,
    dstChains: [SupportedChainId.sonic],
    // dstChains: [SupportedChainId.polygon, SupportedChainId.sonic],
  },
  // {
  //   chainId: 137,
  //   tokenAddress: "0xDE5ed76E7c05eC5e4572CfC88d1ACEA165109E44",
  //   chainName: "polygon",
  //   tokenName: "DEUS",
  //   decimals: 18,
  //   dstChains: [SupportedChainId.fantom],
  // },
];

// MB tokens information

export const MBTokenList = [
  {
    chainId: 8453,
    tokenAddress: "0x8D4aE54e70614c21162A0faF1e0273492CC0078a",
    chainName: "base",
    tokenName: "MetaSYME",
    decimals: 18,
    gateWayAddress: "0x379FCb0C04EFC00BA5862807B9BD1450EE788B69",
    gatewayNativeAddress: "0xe094C430903C3Fe539D639123e94cE06D1feCB4d",
  },
  {
    chainId: 146,
    tokenAddress: "0x8D4aE54e70614c21162A0faF1e0273492CC0078a",
    chainName: "sonic",
    tokenName: "MetaSYME",
    decimals: 18,
    gateWayAddress: "0xc98C0dA6612F5fb0D8032DCA74B7E43DB1017656",
    gatewayNativeAddress: "0x99E7bD153585368c975FcFcdD2cCF0B5172963Ad",
  },
  {
    chainId: 146,
    tokenAddress: "0xF9a9340A638674778c2c8cB3402bEbB17B8EB4D0",
    chainName: "sonic",
    tokenName: "MetaDEUS",
    decimals: 18,
    gateWayAddress: "0x2fc8fFe09bCA00019BFA19B4a6b67081F38e1bf9",
    gatewayNativeAddress: "0xDE55B113A27Cc0c5893CAa6Ee1C020b6B46650C0",
  },
  {
    chainId: 250,
    tokenAddress: "0xF4d5Fcdb12B9310Fac3024987f03Ac967883040B",
    chainName: "fantom",
    tokenName: "mbDEUS",
    decimals: 18,
    gateWayAddress: "0x48B4b337938b1347f5E1BFb442b556aeF93375C2",
    gatewayNativeAddress: "0xDE55B113A27Cc0c5893CAa6Ee1C020b6B46650C0",
  },
  // {
  //   chainId: 137,
  //   tokenAddress: "0xF4d5Fcdb12B9310Fac3024987f03Ac967883040B",
  //   chainName: "polygon",
  //   tokenName: "mbDEUS",
  //   decimals: 18,
  //   gateWayAddress: "0x48B4b337938b1347f5E1BFb442b556aeF93375C2",
  // },
];

//Bridge contract addresses
export const BridgeAddresses: BridgeAddressProps = {
  250: "0x75EdEfF2F70d31a316B780fBd0949778f9E26aAB",
  // 137: "0x75EdEfF2F70d31a316B780fBd0949778f9E26aAB",
  8453: "0x75EdEfF2F70d31a316B780fBd0949778f9E26aAB",
  146: "0x75EdEfF2F70d31a316B780fBd0949778f9E26aAB",
};

export const TxExplorerUrl: BridgeAddressProps = {
  250: "https://ftmscan.com/tx/",
  137: "https://polygonscan.com/tx/",
  146: "https://sonicscan.org/tx/",
  8453: "https://basescan.org/tx/",
};
