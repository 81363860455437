import { fantom, polygon, base } from "wagmi/chains";
import { Chain } from "wagmi/chains";
import { ChainEId, ChainLogos } from "../types/types";

const sonic = {
  id: 146,
  name: "Sonic",
  network: "sonic",
  rpcUrls: {
    default: { http: ["https://rpc.soniclabs.com"] },
  },
  nativeCurrency: {
    name: "Sonic",
    symbol: "Sonic",
    decimals: 18,
  },
  blockExplorers: {
    default: {
      name: "Fantom Sonic Explorer",
      url: "https://explorer.soniclabs.com",
    },
  },
  testnet: false,
};

export const ChainsLogo: ChainLogos = {
  250: "https://cryptologos.cc/logos/fantom-ftm-logo.png?v=035",
  137: "https://cryptologos.cc/logos/polygon-matic-logo.png?v=040",
  146: "https://icons.llamao.fi/icons/chains/rsz_sonic.jpg",
  8453: "https://raw.githubusercontent.com/base-org/brand-kit/main/logo/in-product/Base_Network_Logo.svg",
};

export const supportedChains: Chain[] = [
  {
    ...fantom,
    rpcUrls: {
      ...fantom.rpcUrls,
      default: { http: ["https://rpc.ftm.tools"] },
      public: { http: ["https://rpc.ftm.tools"] },
    },
  },

  // polygon,
  base,
  sonic as any,
];

export const ChainEIds: ChainEId = {
  250: 30112,
  // 137: 30109,
  146: 30332,
  8453: 30184,
};

export const EIdsChain: ChainEId = {
  30112: 250,
  // 30109: 137,
  30332: 146,
  30184: 8453,
};

export enum SupportedChainId {
  fantom = 250,
  // polygon = 137,
  sonic = 146,
  base = 8453,
}

export const SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(
  SupportedChainId
).filter((id) => typeof id === "number") as SupportedChainId[];

export const pairChains: Record<SupportedChainId, SupportedChainId[]> = {
  [SupportedChainId.fantom]: [SupportedChainId.sonic],
  // [SupportedChainId.fantom]: [SupportedChainId.polygon, SupportedChainId.sonic],
  // [SupportedChainId.polygon]: [SupportedChainId.fantom],
  [SupportedChainId.sonic]: [SupportedChainId.base, SupportedChainId.fantom],
  [SupportedChainId.base]: [SupportedChainId.sonic],
};
