import { useEffect, useState } from "react";
import useWalletAccount from "../web3/hooks/useWalletAccount";
import { pairChains, SupportedChainId, supportedChains } from "../web3/chains";
import { Chain } from "viem";
import { useBridgeContext } from "@/context/bridgeProvider";

export const useDestinationChainList = () => {
  const { connectedChain, connectedChainId } = useWalletAccount();
  const { selectedTokenToBridge } = useBridgeContext();
  const [destinationChainList, setDestinationChainList] = useState<
    Chain[] | null
  >(null);

  useEffect(() => {
    if (connectedChain) {
      const validChains = selectedTokenToBridge
        ? selectedTokenToBridge.dstChains
        : pairChains[connectedChainId as SupportedChainId];
      setDestinationChainList(
        supportedChains.filter((chain) => validChains.includes(chain.id))
      );
    }
  }, [connectedChain, selectedTokenToBridge]);

  return { destinationChainList };
};
