"use client";

import { useEffect, useRef, useState } from "react";
import { realTokenList } from "../../../constants/contractAddresses";
import { truncateAddress } from "../../../utils/truncateAddress";
import { ChainsLogo } from "../../../web3/chains";
import { useBridgeContext } from "@/context/bridgeProvider";
import { ArrowDown } from "../../../svgIcons/icons";
import { useOutsideClick } from "../../../hooks/useOutSideClick";
import { TokenProp } from "../../../types/types";
import useWalletAccount from "../../../web3/hooks/useWalletAccount";
import { fromWei } from "../../../utils/web3BigNumbers";

const DisplayTokenList = () => {
  const { account, connectedChainId } = useWalletAccount();

  const {
    tokensBalance,
    handleSelectTokenToBridge,
    selectedTokenToBridge,
    selectedTokenName,
    setSelectedTokenName,
  } = useBridgeContext();
  const ref = useRef<HTMLDivElement>(null);

  useOutsideClick(ref, () => {
    if (showItems) handleShowItems();
  });

  const [showItems, setShowItems] = useState<boolean>(false);

  useEffect(() => {
    if (selectedTokenToBridge)
      setSelectedTokenName(selectedTokenToBridge.tokenName);
  }, [selectedTokenToBridge]);

  const [filteredRealToken, setFilteredRealToken] = useState<
    TokenProp[] | null
  >(null);

  useEffect(() => {
    if (connectedChainId)
      setFilteredRealToken(
        realTokenList.filter((token) => token.chainId === connectedChainId)
      );
  }, [connectedChainId]);

  const handleShowItems = () => {
    setShowItems(!showItems);
  };

  const handleSelectToken = (token: TokenProp) => {
    setShowItems(false);
    handleSelectTokenToBridge(token);
  };

  const handleChange = (e: string) => {
    setSelectedTokenName(e);
    setShowItems(true);
    handleSelectTokenToBridge(null);
    const res = realTokenList
      .filter((token) => token.chainId === connectedChainId)
      .filter(
        (token) =>
          token.tokenName.toLocaleLowerCase().includes(e.toLocaleLowerCase()) ||
          token.chainName.toLocaleLowerCase().includes(e.toLocaleLowerCase()) ||
          token.chainId === Number(e)
      );
    setFilteredRealToken(res);
  };

  return (
    <div className="mb-10 sm:px-0 px-4 select-none relative" ref={ref}>
      <p className="mb-2 ">Select Token</p>
      <div
        onClick={() => handleShowItems()}
        className="border w-full border-[#445b5b] rounded-lg flex items-center px-3 bg-[#0c0f12] cursor-pointer"
      >
        {selectedTokenToBridge && (
          <img
            className="rounded-full"
            src={ChainsLogo[selectedTokenToBridge.chainId]}
            width={20}
          />
        )}
        <input
          placeholder="Search by name / symbol / chain"
          className="flex items-center relative bg-inherit border rounded-lg  h-[52px] px-3 w-full"
          value={selectedTokenName ? selectedTokenName : ""}
          onChange={(e) => handleChange(e.target.value)}
          disabled={!account}
        />
        <div>
          <ArrowDown />
        </div>
      </div>

      <div
        className={`grid grid-rows-[0fr] left-0 sm:px-0 px-4 transition-all duration-300 absolute z-30 min-w-full ${
          showItems ? "grid-rows-[1fr]" : "grid-rows-[0fr]"
        } `}
      >
        <div className="overflow-hidden">
          {filteredRealToken && (
            <div className="flex flex-col gap-2 mt-1  border border-[#445b5b] py-4 px-3 rounded-lg bg-[#0c0f12]">
              {filteredRealToken.map((token, index) => (
                <div
                  key={index}
                  className="flex items-center gap-3 cursor-pointer  justify-between hover:bg-slate-500 p-3 rounded-lg"
                  onClick={() => handleSelectToken(token)}
                >
                  <div className="flex items-center gap-2">
                    <img
                      className="rounded-full"
                      src={ChainsLogo[token.chainId]}
                      width={20}
                    />
                    <div>{token.tokenName}</div>
                    {/* <div>{token.chainName}</div> */}
                    <div>{truncateAddress(token.tokenAddress)}</div>
                  </div>
                  {tokensBalance && tokensBalance.length > 0 && account && (
                    <div className="flex gap-1">
                      <p>balance: </p>
                      {Number(
                        fromWei(
                          tokensBalance
                            .find(
                              (item) => item.tokenAddress == token.tokenAddress
                            )!
                            .userTokenBalance!.toString()
                        )
                      ).toFixed(2)}
                    </div>
                  )}
                </div>
              ))}
              {!filteredRealToken ||
                (filteredRealToken.length === 0 && <div>No items found</div>)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DisplayTokenList;
