"use client";

import React from "react";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ChainsLogo } from "../chains";

const WalletConnectButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    className="primary-btn radial-gradient-button"
                    onClick={openConnectModal}
                    type="button"
                  >
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    className="text-red-500"
                    onClick={openChainModal}
                    type="button"
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 12,
                  }}
                >
                  {connected && mounted && !chain.iconUrl && (
                    <img
                      src={ChainsLogo[chain.id]}
                      width={25}
                      onClick={() => openChainModal()}
                      className="cursor-pointer rounded-full"
                    />
                  )}
                  <img
                    className="cursor-pointer"
                    onClick={() => openChainModal()}
                    src={chain.iconUrl}
                  />
                  <button
                    onClick={openAccountModal}
                    type="button"
                    className="primary-btn cursor-pointer flex radial-gradient-button"
                  >
                    {account.displayName}
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default WalletConnectButton;
