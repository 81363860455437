"use client";
import { Address, erc20Abi } from "viem";
import gatewayAbi from "../abis/gatewayAbi";
import { MBTokenList } from "../../constants/contractAddresses";
import { config } from "../config";
import { useReadContracts } from "wagmi";
import { useEffect, useState } from "react";

export const useFetchGatewayNativeTokenAddress = () => {
  const contracts = [];

  const [tokensWithNativeAddress, setTokenWithNativeAddress] = useState();

  for (let i = 0; i < MBTokenList.length; i++) {
    const token = MBTokenList[i];
    contracts.push({
      abi: gatewayAbi,
      address: token.gateWayAddress as Address,
      functionName: "nativeToken",
      chainId: token.chainId,
    });
  }

  const { data: nativeAddresses, refetch: refetchNativeAddresses } =
    useReadContracts({
      config,
      contracts: contracts,
    });

  useEffect(() => {
    refetchNativeAddresses();
  }, [refetchNativeAddresses]);

  useEffect(() => {
    if (nativeAddresses) {
      const contracts = [];
      for (let i = 0; i < MBTokenList.length; i++) {
        const token = MBTokenList[i];
        contracts.push({
          abi: erc20Abi,
          address: nativeAddresses[i].result as Address,
          functionName: "balanceOf",
          args: [token.gateWayAddress],
          chainId: token.chainId,
        });
      }

      setTokenWithNativeAddress(contracts as any);
    }
  }, [nativeAddresses]);

  return { refetchNativeAddresses, nativeAddresses, tokensWithNativeAddress };
};
