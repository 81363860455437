"use client";
import { ChainsLogo, supportedChains } from "../../web3/chains";
import WalletConnectButton from "../../web3/components/WalletConnectButton";
import useWalletAccount from "../../web3/hooks/useWalletAccount";
import { useDisconnect } from "wagmi";
import Modal from "./Modal";
import NetworksModal from "../NetworksModal/NetworksModal";
import { useState } from "react";
import { LogoutIcon, NotificationIcon } from "../../svgIcons/icons";
import { useBridgeContext } from "@/context/bridgeProvider";
import Link from "next/link";

const Header = () => {
  const { connectedChainId, isConnected, account, isWrongNetWork } =
    useWalletAccount();
  const { disconnect } = useDisconnect();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handelOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const { setIsTxModalOpen, bridgeHash, isDelivered } = useBridgeContext();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const handleOpenMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="relative">
      <div className="w-full h-20 justify-between items-center text-white border-b border-gray-700 px-5 hidden sm:flex">
        <div className="header__left flex gap-4 items-center">
          <div className="header_Logo cursor-pointer mr-2">
            <img
              src={"../../assets/images/logo.png"}
              alt="Logo"
              className="w-16 h-16"
            />
          </div>
          <Link href="/">Bridge</Link>
          {account && (
            <Link href={`/transactions/${account}`}>Transactions History</Link>
          )}

          <div
            className="cursor-pointer"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSf2KIXx9_tqi7cA2UkST6DvSiH-xkbqWSw6rl2gMfS8H53V9w/viewform"
              )
            }
          >
            Contact
          </div>
        </div>
        <div className="header_right flex gap-3 items-center">
          {!isDelivered && bridgeHash && (
            <div
              className="mr-1 cursor-pointer relative"
              onClick={() => setIsTxModalOpen(true)}
            >
              <div className="absolute w-2 h-2 bg-red-400 rounded-full -right-1"></div>
              <NotificationIcon />
            </div>
          )}
          {/* {connectedChainId && !isWrongNetWork && (
            <img
              src={ChainsLogo[connectedChainId]}
              width={25}
              onClick={() => handelOpenModal()}
              className="cursor-pointer rounded-full"
            />
          )} */}

          <WalletConnectButton />

          {isConnected && (
            <div
              className="h-4 w-4 cursor-pointer"
              onClick={() => disconnect()}
            >
              <LogoutIcon />
            </div>
          )}
        </div>
        <Modal
          titleLeft="Select Network"
          isOpen={isModalOpen}
          closeModalHandler={handelOpenModal}
          size="large"
        >
          <NetworksModal
            networks={supportedChains}
            closeModalHandler={handelOpenModal}
            isDestination={false}
          />
        </Modal>
      </div>
      <div className=" sm:hidden border-b border-gray-700">
        <div
          className="p-3 mr-4 flex items-center justify-between"
          onClick={handleOpenMenu}
        >
          <img
            src={"../../assets/images/logo.png"}
            alt="Logo"
            className="w-16 h-16"
          />
          <div className="flex flex-col gap-1 cursor-pointer relative z-[100]">
            <div className="w-7 rounded-lg h-1 bg-white"></div>
            <div className="w-7 rounded-lg h-1 bg-white"></div>
            <div className="w-7 rounded-lg h-1 bg-white"></div>
          </div>
        </div>
      </div>
      <div
        className={` border border-gray-700 sm:hidden absolute  bg-gray-800 rounded-2xl p-4 left-2 right-2 flex flex-col gap-8 text-white transform transition-all duration-700 ${
          !isMenuOpen ? "opacity-0 -top-60" : "opacity-100 top-3"
        }`}
      >
        <div className="flex gap-3 items-center">
          {connectedChainId && !isWrongNetWork && (
            <img
              src={ChainsLogo[connectedChainId]}
              width={25}
              onClick={() => handelOpenModal()}
              className="cursor-pointer rounded-full"
            />
          )}

          <WalletConnectButton />

          {isConnected && (
            <div
              className="h-4 w-4 cursor-pointer"
              onClick={() => disconnect()}
            >
              <LogoutIcon />
            </div>
          )}
        </div>

        <Link href="/">Bridge</Link>
        {account && (
          <Link href={`/transactions/${account}`}>Transactions History</Link>
        )}

        <div
          className="cursor-pointer"
          onClick={() =>
            window.open(
              "https://docs.google.com/forms/d/e/1FAIpQLSf2KIXx9_tqi7cA2UkST6DvSiH-xkbqWSw6rl2gMfS8H53V9w/viewform"
            )
          }
        >
          Contact
        </div>
      </div>
    </div>
  );
};

export default Header;
