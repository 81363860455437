"use client";
import useWalletAccount from "../../web3/hooks/useWalletAccount";
import { NetworkToDisplay } from "./components/NetworkToDisplay";
import { DestinationNetworkToDisplay } from "./components/DestinationNetworkToDisplay";
import WalletConnectButton from "../../web3/components/WalletConnectButton";
import DisplayTokenList from "./components/DisplayTokenList";
import { useBridgeContext } from "@/context/bridgeProvider";
import Actions from "./components/Actions";
import BridgeAmountInput from "./components/BridgeAmountInput";
import TransactionStatusChecker from "./components/TransactionStatusChecker";
import CheckUserAssets from "./components/CheckUserAssets";

const Bridge = () => {
  const { connectedChain, isWrongNetWork, isConnected } = useWalletAccount();
  const { destinationChain, selectedTokenToBridge } = useBridgeContext();

  return (
    <div>
      <div className="flex w-full flex-col items-center text-white pt-8">
        <div className="mb-10 flex flex-col justify-center items-center min-h-40">
          <p className="mb-3">Select Network</p>
          {!isWrongNetWork && isConnected ? (
            <div className="flex items-center justify-center gap-3 min-h-16">
              <NetworkToDisplay />
              <p>{`>`}</p>

              <DestinationNetworkToDisplay />
            </div>
          ) : isWrongNetWork && isConnected ? (
            <div>Unsupported Network</div>
          ) : (
            <WalletConnectButton />
          )}
        </div>

        <div className="w-full max-w-[450px] ">
          <DisplayTokenList />
          <BridgeAmountInput />
        </div>

        <div className="px-4 sm:px-0 flex flex-col gap-2 min-h-16 transition-all duration-200 leading-6">
          {connectedChain && destinationChain && selectedTokenToBridge && (
            <p>
              Bridge from {connectedChain.name} to {destinationChain.name}
            </p>
          )}
        </div>

        <div className="mb-8 h-6">
          <TransactionStatusChecker />
        </div>

        <Actions />
      </div>
      <div className="mt-14 px-5  text-[12px] sm:text-base">
        <CheckUserAssets />
      </div>
    </div>
  );
};

export default Bridge;
